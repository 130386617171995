import React, { ReactNode } from "react";
import { css } from "emotion";
import { useTranslation } from "../../i18n/useTranslation";
import { useStudentScheduleMachine } from "./student-schedule-machine";
import { getLessonTimes } from "../../utils/getLessonTimes";
import { Lesson } from "@turbo-schedule/common";

export const WholeWeekSchedule = () => {

	const t = useTranslation();

	const SSM = useStudentScheduleMachine();

	const scheduleByDays = SSM.state.context.participant.scheduleByDays;

	const longestDay = scheduleByDays.sort((a,b) => a.length - b.length)[0];
	const date = new Date();

	return (
		<table className={css`
			flex: 5;
			flex-shrink: 3;

			table-layout: auto; /*auto || fixed */

			overflow: auto;

			margin: 2em;

			border: 4px solid #000;
		`}>
			{/* {isDesktop ? `desktop(${windowWidth}px)` : `mobile(${windowWidth}px)`} */}
			<thead className={css`
				border-bottom: 2px solid #000
				`}
			>
				<tr>
					<TableH>{date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1}-{date.getDate()}</TableH>
					{Array(5).fill(0).map((_, i) => {
						return <TableH key={i}>{t("weekday")(i)}</TableH>
					})}
				</tr>
			</thead>
			<tbody>
					{longestDay.map((_, lesson) => {
						const time = getLessonTimes(lesson);
						return <tr key={lesson}>
							<TableH>{time.startTime}<br/>{time.endTime}</TableH>
							{scheduleByDays.map((day, i) => {
								return <LessonItem key={i} data={day[lesson]}/>
							})}
						</tr>
					})}
			</tbody>
		</table>
	)
}

const LessonItem = ({data}: {data: Lesson}) => {
	return (
		<td className={css`
			border: 1px solid #000;
			padding: 0.1em 0.25em;
			background: rgba(64, 64, 64, 0.25);
			text-align: center;
			vertical-align: middle;
			`}
		>{data.name}</td>
	)
}

const TableH = ({children}: {children: ReactNode}) => {
	return (
		<th className={css`
			border: 2px solid #000;
			padding: 0.25em 0.5em;
			background: rgba(16, 16, 16, 0.25);
			vertical-align: middle;
			`}
		>{children}</th>
	)
}
// stateM.context.participant.scheduleByDays.map((lessonsArray, index) => (
// 							<div key={index} style={weekStyles}>
// 								<h3 style={{ padding: "1em 2em", color:"red !important" }}>{t("weekday")(1)}</h3>

// 								<LessonsList lessons={lessonsArray} />
// 							</div>
// 						))
